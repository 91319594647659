.auth-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-image: url('/assets/img/fondo-auth.jpg');
	background-position: center right;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100vh;
	.form {
		width: 390px;
		display: flex;
		flex-direction: column;
		background-color: $primary;
		padding: 40px 55px;
		height: 100%;
		color: $white;
		.container-logos {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 20px;
			img {
				max-width: 120px;
				margin-right: 20px;
			}
		}
		.container-text {
			text-align: left;
			margin-bottom: 20px;
			display: block;
		}
		.form-control {
			margin-bottom: 20px;
			label {
				color: $white;
			}
		}
		.link {
			margin-top: 20px;
			color: $white;
		}
		.login-google {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			background-color: $white;
			padding: 10px 7px;
			text-decoration: none;
			color: $black;
			border-radius: 2px;
			margin-top: 20px;
			img {
				width: 30px;
				height: 30px;
				margin-right: 15px;
			}
		}
	}
}
