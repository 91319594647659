$primary: #23c8be;
$secondary: #527a78;
$gray: #dcdeda;
$white: #fff;
$black: #000;
$semiBlack: #4d4d4d;
$danger: #b10303;
$warning: orange;
$warningDark: #ff6700;
$info: #007bff;
$success: #3ac200;
$blue: #1476ad;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');
// Resets y default
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
}
.App {
	display: block;
	width: 100%;
	overflow: hidden;
	height: 100vh;
	background-color: $gray;
}
// Loader
.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: fade-out($color: $white, $amount: 0.2);
	z-index: 100;

	.loader {
		width: 50px;
		aspect-ratio: 1;
		display: grid;
		border: 3px solid #0000;
		border-radius: 50%;
		border-right-color: $primary;
		animation: l15 1s infinite linear;

		&::before,
		&::after {
			content: '';
			grid-area: 1/1;
			margin: 3px;
			border: inherit;
			border-radius: 50%;
			animation: l15 2s infinite;
		}

		&::after {
			margin: 10px;
			animation-duration: 3s;
		}
	}

	@keyframes l15 {
		100% {
			transform: rotate(1turn);
		}
	}
}
//Alerts
.alert-container {
	top: 45px;
	right: 10px;
	position: fixed;
	z-index: 100;
	display: flex;
	width: auto;
	max-width: 400px;
	transition: 0.35s;
	transform: translateX(410px);
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
	color: white;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	section {
		padding: 15px;
		text-align: left;
		flex: 1;
	}
	p {
		font-size: 17px;
		font-weight: 400;
	}
	a {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.alert-info {
		background-color: #b7d4ff;
		color: #0065ff;
		p {
			color: #0065ff;
		}
		a > svg {
			fill: #0065ff;
		}
	}

	&.alert-success {
		background-color: rgba(158, 255, 158, 0.8392156863);
		color: #005f04;
		p {
			color: #005f04;
		}
		a > svg {
			fill: #005f04;
		}
	}

	&.alert-warning {
		background-color: #ffe5b6;
		color: #af7100;
		p {
			color: #af7100;
		}
		a > svg {
			fill: #af7100;
		}
	}

	&.alert-danger {
		background-color: #ffc3c3;
		color: $danger;
		p {
			color: $danger;
		}
		a > svg {
			fill: $danger;
		}
	}
}
.alertIn {
	transform: translateX(0);
}
// Inputs
.form-control {
	display: flex;
	width: 100%;
	margin-bottom: 12px;
	flex-direction: column;
	position: relative;

	label {
		order: 1;
		display: block;
		font-size: 17px;
		color: $black;
		margin-bottom: 7px;
	}
	small {
		font-size: 12px;
		display: block;
		order: 3;
	}

	input:disabled,
	textarea:disabled {
		background-color: #d7d7d7;
	}

	input,
	select.native-select,
	textarea {
		order: 2;
		display: block;
		width: 100%;
		font-size: 15px;
		padding: 10px 15px;
		border-radius: 7px;
		background-color: $white;
		color: $black;
		border: 1px solid $gray;
		resize: none;
	}
	.select__control {
		order: 2;
		width: 100%;
		font-size: 15px;
		padding: 2px;
		border-radius: 7px;
		background-color: $white;
		color: $black;
		border: 1px solid $gray;
		div.select_input__value-container.select__value-container--is-multi {
			flex-wrap: nowrap !important;
		}
	}
	.select__control.disabled {
		background-color: #d7d7d7;
	}

	.select_input__control {
		border: none !important;
		background-color: transparent;
		box-shadow: none !important;
	}
	.select_input__menu {
		z-index: 20;
	}
	.select_input__menu-list {
		&::-webkit-scrollbar {
			width: 8px;
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: transparent;
		}

		&:hover {
			&::-webkit-scrollbar-thumb {
				background: $gray;
				border: 1px solid $gray;
			}
		}
	}
	.show-no-show {
		display: block;
		width: 25px;
		height: 20px;
		object-fit: contain;
		position: absolute;
		bottom: 12px;
		right: 10px;
		z-index: 2;
	}
}

.version {
	display: block;
	padding: 20px 0;
	text-align: center;
	p {
		font-size: 14px;
		color: $white;
	}
}
// Botones
button {
	display: block;
	width: 100%;
	padding: 5px 0;
	font-size: 17px;
	border: none;
	cursor: pointer;
	border-radius: 7px;
	small {
		display: block;
		font-size: 0.8rem;
	}
}

button:disabled {
	opacity: 0.7;
	cursor: not-allowed;
}
button.primary {
	background-color: $blue;
	color: $white;
	font-weight: 600;
}
.link {
	font-size: 17px;
	color: $primary;
	text-decoration: underline;
}
// Card
.card {
	background-color: white;
	border: 1px solid $gray;
	border-radius: 4px;
	padding: 20px;
	display: block;
	&.success {
		background-color: #9eff9ed6;
		color: #005f04;
	}

	&.danger {
		background-color: #ff480081;
		color: #ff0000;
	}
	&.happy {
		background-color: #ffe60080;
		color: #121212;
	}

	&.warning {
		background-color: #ffad7b;
		color: #121212;
	}

	&.info {
		background-color: #b5d9ff;
		color: $info;
	}

	&.unknown {
		background-color: #a3a3a3d0;
		color: #fff;
	}
}
// Row
.row {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex: 1;
}
// Tags
.tags {
	display: block;
	width: 100%;
	margin: 4px 0;
	span {
		display: inline-block;
		background-color: $primary;
		color: $white;
		padding: 2px 7px;
		border-radius: 7px;
		font-size: 14px;
		margin: 2px;
		a {
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 16px;
			margin-left: 10px;
			font-size: 12px;
			width: 19px;
			height: 19px;
			color: $white;
			border-radius: 50%;
			text-decoration: none;
			border: 1px solid $white;
		}
	}
}
// Tabs
.tabs {
	display: flex;
	flex-direction: column;
	width: 100%;

	.tab-buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin-bottom: 20px;

		button {
			display: inline-block;
			width: fit-content;
			padding: 10px 30px;
			margin-right: 5px;
		}
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	.tab-content {
		opacity: 0;
		display: none;

		&.active {
			animation: fadeIn 0.5s forwards;
			display: block;
			opacity: 1;
		}
	}
}
.span-status {
	display: inline-block;
	padding: 4px 10px;
	border-radius: 4px;
	font-size: 15px;
	text-align: center;
	font-weight: 600;
	&.small {
		font-size: 11px;
		padding: 1px 5px;
	}
	&.green {
		background-color: #9eff9ed6;
		color: #005f04;
	}

	&.gray {
		background-color: #d1d1d1;
		color: #4d4d4d;
	}
}
// Tablas
.table {
	display: block;
	width: 100%;

	.actions-and-filters {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-bottom: 15px;
		border-bottom: 1px solid $gray;

		.filters {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 50%;

			.form-control {
				margin-right: 10px;
				max-width: 50%;
				margin-bottom: 0;
			}
		}

		.actions {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			button,
			.container-dropdown > a {
				padding: 11px 20px;
				margin-left: 10px;
			}
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;
	}

	td {
		padding: 10px;
		border-bottom: 0.5px solid $gray;
		text-align: left;
	}

	thead {
		top: -20px;
		background-color: $white;
		position: sticky;
		z-index: 2;
		box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.5);
	}

	tr {
		&:hover {
			background-color: $gray;
		}
	}

	th {
		text-align: left;

		button {
			display: flex;
			justify-content: center;
			justify-content: flex-start;
			padding: 15px 10px;
			background-color: transparent;
			border-radius: 0;
			position: relative;

			span {
				font-weight: 600;
			}

			&::after {
				content: '';
				width: 8px;
				height: 8px;
				border-top: 2px solid $semiBlack;
				border-left: 2px solid $semiBlack;
				border-radius: 2px;
				display: none;
				position: absolute;
				right: 15px;
				top: 20px;
				transition: all 0.2s;
			}

			&.up {
				&::after {
					transform: rotate(45deg) translateY(5px);
					display: block;
				}
			}

			&.down {
				&::after {
					transform: rotate(-135deg);
					display: block;
				}
			}
		}
	}

	.pagination {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 0;

		strong {
			display: block;
			white-space: nowrap;
			margin-right: 10px;
		}

		.controls {
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid $gray;
			border-radius: 7px;
			overflow: hidden;
			margin: 0 10px;

			span {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 35px;
				height: 35px;
				padding: 0;
				border-right: 1px solid $gray;
			}

			button {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 35px;
				height: 35px;
				padding: 0;
				border-radius: 0;
				background-color: transparent;
				transition: all 0.2s;
				border-right: 1px solid $gray;
				font-size: 14px;

				&:last-child {
					border-right: none;
				}

				&::before,
				&::after {
					transition: all 0.2s;
				}

				&.active {
					background-color: $primary;
					color: $white;

					&::before,
					&::after {
						border-color: $white;
					}
				}
			}

			button:disabled {
				background-color: transparent;
				opacity: 0.5;
				cursor: not-allowed;

				&::before,
				&::after {
					border-color: $semiBlack;
				}
			}

			.firts-page {
				&::before,
				&::after {
					content: '';
					display: inline-block;
					width: 5px;
					height: 5px;
					border-top: 2px solid $semiBlack;
					border-left: 2px solid $semiBlack;
					transform: rotate(-45deg);
					border-radius: 2px;
				}
			}

			.prev {
				&::before {
					content: '';
					display: inline-block;
					width: 5px;
					height: 5px;
					border-top: 2px solid $semiBlack;
					border-left: 2px solid $semiBlack;
					transform: rotate(-45deg);
					border-radius: 2px;
				}
			}

			.next {
				&::before {
					content: '';
					display: inline-block;
					width: 5px;
					height: 5px;
					border-top: 2px solid $semiBlack;
					border-left: 2px solid $semiBlack;
					transform: rotate(135deg);
					border-radius: 2px;
				}
			}

			.last-page {
				&::before,
				&::after {
					content: '';
					display: inline-block;
					width: 5px;
					height: 5px;
					border-top: 2px solid $semiBlack;
					border-left: 2px solid $semiBlack;
					transform: rotate(135deg);
					border-radius: 2px;
				}
			}
		}

		.form-control {
			margin: 0;
			width: 250px;

			.select__control {
				padding: 0;
				font-size: 15px;
			}
		}
	}

	.btns-table {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		button {
			margin-right: 7px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
// Modal
.modal {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
	padding: 40px 0;
	overflow-y: auto;
	max-height: 100vh;

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: transparent;
	}

	&:hover {
		&::-webkit-scrollbar-thumb {
			background: $gray;
			border: 1px solid $gray;
		}
	}

	@keyframes showModal {
		0% {
			opacity: 0;
			transform: translate(-50%, 10%);
		}

		100% {
			opacity: 1;
			transform: translate(-50%, 0);
		}
	}

	.container-confirm {
		background-color: $white;
		padding: 35px;
		border-radius: 20px;
		max-width: 470px;
		position: relative;
		left: 50%;
		top: 20%;
		transform: translate(-50%, 0);
		animation: showModal 0.2s ease-out;
		text-align: center;

		h2 {
			margin-bottom: 12px;
		}
	}

	.container-modal {
		background-color: $white;
		padding: 35px;
		border-radius: 20px;
		width: 450px;
		max-width: 96vw;
		position: relative;
		left: 50%;
		top: 0;
		transform: translate(-50%, 0);
		animation: showModal 0.2s ease-out;

		h2 {
			margin-bottom: 12px;
		}
	}

	.close-modal {
		display: flex;
		background-color: $primary;
		height: 30px;
		width: 30px;
		border-radius: 15px;
		position: absolute;
		top: 15px;
		right: 15px;
		justify-content: center;
		align-items: center;

		img {
			display: block;
			width: 12px;
			height: 12px;
			object-fit: contain;
		}
	}
}
.switch {
	input {
		display: none;
	}

	input:checked + span {
		background-color: $primary;

		&::before {
			-webkit-transform: translateX(20px);
			-ms-transform: translateX(20px);
			transform: translateX(20px);
		}
	}

	input:disabled + span {
		background-color: $gray;
	}
	input:disabled + span::before {
		background-color: $gray;
	}

	span {
		display: inline-block;
		vertical-align: middle;
		width: 47px;
		height: 26px;
		background-color: $gray;
		border: 1px solid $gray;
		margin-right: 10px;
		padding: 2px;
		border-radius: 12px;
		transition: background-color 0.2s;

		&::before {
			content: '';
			-webkit-transition: 0.2s;
			transition: 0.2s;
			display: block;
			width: 20px;
			height: 20px;
			background-color: $white;
			border-radius: 10px;
			box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.5);
		}
	}
}
// 404
.not-found {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	flex-direction: column;

	span {
		color: $semiBlack;
		font-size: 2rem;
	}

	h1 {
		font-size: 10rem;
		font-weight: 600;
		padding-bottom: 10px;
		border-bottom: 1px solid $primary;
		margin-bottom: 20px;
	}

	a {
		font-weight: 600;
		margin-top: 15px;
	}
}
.title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
	width: 100%;
	margin-bottom: 15px;

	h1 {
		padding: 0;
	}

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background-color: $primary;
		margin-right: 10px;
		transition: all 0.2s;
		&::before {
			content: '';
			width: 10px;
			height: 10px;
			border-left: 3px solid $white;
			border-top: 3px solid $white;
			border-radius: 2px;
			transform: rotate(-45deg);
			transition: all 0.2s;
		}

		&::after {
			content: '';
			width: 15px;
			height: 3px;
			background-color: $white;
			margin-left: -12px;
			border-radius: 2px;
			transition: all 0.2s;
		}
	}
}

@media print {
	.hide-print {
		display: none !important;
	}
	button {
		display: none !important;
	}
	.alert-container,
	.modal,
	.loader-container {
		display: none !important;
	}
	.App {
		height: auto !important;
	}
	.full-print {
		display: block !important;
		height: auto !important;
		padding: 10px !important;
	}
}

// Estilos de la plataforma
@import 'auth';
@import 'dashboard';
// breakpoint 1 del responsive
@media screen and (max-width: 768px) {
	@import 'bp1';
}
