// Auth
.auth-container {
	justify-content: center;
	.form {
		min-width: 320px;
		width: 50%;
		height: auto;
		border-radius: 7px;
		box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.5);
		padding: 30px;
		.container-logos {
			margin-bottom: 15px;
			display: flex;
			justify-content: 0;
			align-items: center;
			img {
				max-width: 100px;
				margin: 0 auto;
			}
		}
		.container-text {
			margin-bottom: 15px;
		}
		.form-control {
			margin-bottom: 15px;
		}
		.link {
			margin-top: 15px;
		}
		.login-google {
			padding: 8px 7px;
			margin-top: 15px;
			img {
				width: 25px;
				height: 25px;
				margin-right: 10px;
			}
		}
	}
}
