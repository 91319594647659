.dashboard {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
	width: 100%;
	aside {
		width: 300px;
		background-color: $primary;
		height: 100%;
		padding: 40px 20px;
		.container-logos {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 20px;
			img {
				max-width: 100px;
				margin-right: 15px;
			}
		}
		nav {
			width: 100%;
			display: flex;
			flex-direction: column;
			a {
				display: block;
				width: 100%;
				padding: 12px 15px;
				margin-top: 3px;
				color: $white;
				font-size: 17px;
				text-decoration: none;
				transition: all 0.15s;
				border-radius: 4px;
				&:hover {
					background-color: $white;
					color: $primary;
				}
			}
		}
	}
	section {
		flex: 1;
		display: flex;
		flex-direction: column;
		header {
			width: 100%;
			height: 80px;
			background-color: $secondary;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			color: $white;
			padding: 0 30px;
			.user-container {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				transition: all 0.15s;
				text-decoration: none;
				color: $white;
				padding: 10px 12px;
				border-radius: 5px;
				position: relative;
				&:hover {
					background-color: lighten($secondary, $amount: 5);
				}
				div {
					display: block;
					text-align: right;
					b,
					small {
						display: block;
					}
				}
				span {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 45px;
					height: 45px;
					border-radius: 7px;
					background-color: $white;
					text-align: center;
					margin-left: 15px;
					overflow: hidden;
					img.default {
						display: block;
						width: 55%;
					}
					img.avatar {
						display: block;
						width: 100%;
					}
				}
				input {
					display: none;
				}
				input:checked + .user-menu {
					height: auto;
					padding: 10px;
				}
				.user-menu {
					background-color: $white;
					border-radius: 4px;
					position: absolute;
					top: 110%;
					width: 100%;
					left: 0;
					right: 0;
					overflow: hidden;
					height: 0;
					padding: 0;
					transition: all 0.3s;
					interpolate-size: allow-keywords;
					box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
					z-index: 2;
					a {
						text-decoration: none;
						color: $secondary;
						display: block;
						width: 100%;
						padding: 10px;
						margin: 2px 0;
						text-align: center;
						transform: all 0.2s;
						font-weight: 600;
						&:hover {
							background-color: $gray;
						}
					}
				}
			}
		}
		.content-container {
			padding: 30px 40px;
			display: block;
			overflow-y: auto;
			height: calc(100vh - 80px);
		}
	}
}

// Reordenar preguntas
.drag-questions {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	.container-dragger {
		display: flex;
		flex-direction: column;
		width: 30px;
		height: 25px;
		justify-content: space-between;
		align-items: center;
		margin-right: 20px;
		span {
			width: 100%;
			height: 3px;
			background-color: $gray;
			border-radius: 2px;
		}
	}
	.round-positions {
		display: block;
		width: 30px;
		height: 30px;
		background-color: $primary;
		color: $white;
		border-radius: 50%;
		margin: 0 15px;
		text-align: center;
		line-height: 32px;
	}
	.info-drag {
		flex: 1;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}
.icons {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	img {
		width: 55px;
		margin-right: 15px;
	}
}

// Questionario
.title-question-widget {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 25px;
	img {
		width: 45px;
		margin-right: 10px;
	}
	span {
		display: inline-block;
		padding: 7px 15px;
		border-radius: 8px;
		color: $white;
		font-size: 15px;
		margin-left: 10px;
		&.environmental {
			background-color: $primary;
		}
		&.social {
			background-color: #1476ad;
		}
		&.economic {
			background-color: #b1e227;
		}
	}
}
.step-questions {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	.step {
		display: block;
		width: 30px;
		height: 30px;
		background-color: $gray;
		color: $white;
		border-radius: 50%;
		margin-right: 15px;
		text-align: center;
		line-height: 32px;
	}
}
.step-questions.environmental > .step.active {
	background-color: $primary;
}
.step-questions.social > .step.active {
	background-color: #1476ad;
}
.step-questions.economic > .step.active {
	background-color: #b1e227;
}
.container-questions {
	.card {
		margin: 20px 0;
	}
}
.title-question {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 15px;
	span.circle {
		display: block;
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 50%;
		color: $white;
		font-size: 18px;
		text-align: center;
		margin-right: 20px;
		&.environmental {
			background-color: $primary;
		}
		&.social {
			background-color: #1476ad;
		}
		&.economic {
			background-color: #b1e227;
		}
	}
	span.title {
		display: block;
		width: calc(100% - 60px);
	}
}
.container-option {
	display: block;
	text-align: center;
	width: 450px;
	max-width: 65vw;
	margin: 10px auto;
	.form-control .select__control {
		border: 3px solid $gray;
		font-size: 17px;
		font-weight: 600;
		margin-bottom: 20px;
	}
	.form-control input {
		border: 3px solid $gray;
		background-color: $gray;
	}
	.container-files {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		margin: 10px auto;
		.file {
			display: flex;
			width: 120px;
			height: 150px;
			margin: 5px 7px;
			border: 1px solid $gray;
			border-radius: 4px;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			position: relative;
			.delete-file {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				width: 27px;
				height: 27px;
				border-radius: 3px;
				top: 2px;
				right: 2px;
				background-color: $danger;
				img {
					width: 25px;
					height: 25px;
				}
			}
			img {
				display: block;
				width: 85px;
				margin: 10px auto;
			}
			span {
				display: block;
				font-size: 12px;
				color: $black;
				text-align: center;
				white-space: nowrap;
				text-overflow: ellipsis;
				width: 85%;
				height: 20px;
				overflow: hidden;
			}
		}
	}
	.form-control label {
		font-size: 17px;
		font-weight: 600;
	}
	.answer-poinst,
	.title-files-answers {
		font-size: 17px;
		font-weight: 600;
	}
	.answer-poinst {
		display: inline-block;
		background-color: $primary;
		color: $white;
		padding: 10px 15px;
		border-radius: 22px;
	}
	.file-inline {
		text-align: left;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		text-decoration: none;
		border: 1px solid $gray;
		padding: 10px;
		border-radius: 4px;
		margin-bottom: 5px;
		img {
			width: 45px;
			height: 45px;
			object-fit: contain;
			margin-right: 15px;
		}
		span {
			display: block;
			width: calc(100% - 60px);
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 17px;
			font-weight: 600;
			color: $black;
			white-space: nowrap;
		}
	}
}
.title-icon-dimention {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	img {
		width: 45px;
		height: 45px;
		margin-right: 15px;
		object-fit: contain;
	}
}
.position-table {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background-color: $gray;
	color: $black;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	font-weight: 600;
	&.p1 {
		background-color: $primary;
		color: $white;
	}
	&.p2 {
		background-color: #1476ad;
		color: $white;
	}
	&.p3 {
		background-color: #b1e227;
		color: $white;
	}
}

// Dashboard de aqueducto
.widgets-results {
	display: block;
	margin: 20px 0;
	.card.success {
		margin-right: 0;
		margin-bottom: 20px;
	}
	.card {
		flex: 1;
		margin-right: 20px;
		&:last-child {
			margin-right: 0;
		}
		img {
			width: 65px;
		}
		p {
			color: $primary;
			font-weight: 600;
		}
		.img-alls {
			display: block;
			width: 65px;
			text-align: center;
			img {
				width: 28px;
				margin: 0 2px;
			}
		}
	}
	.progress-bar-container {
		display: block;
		width: 100%;
		height: 15px;
		background-color: $gray;
		border-radius: 10px;
		position: relative;
		.progress {
			display: block;
			height: 100%;
			background-color: $primary;
			border-radius: 10px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
.graphs-and-position {
	.card {
		height: 400px;
		&:first-child {
			flex: 1;
			margin-right: 20px;
		}
		&:last-child {
			width: 350px;
			position: relative;
			p {
				text-align: center;
				font-size: 17px;
				font-weight: 600;
				color: $black;
			}
			img {
				display: block;
				width: 85%;
				margin: 20px auto;
			}
			strong {
				position: absolute;
				top: 55%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: $info;
				font-size: 60px;
			}
		}
	}
}
.widgets-top-positions-and-history {
	margin: 20px 0;
	.card {
		flex: 1;
		&:first-child {
			margin-right: 20px;
		}
	}
}
.container-graph {
	margin-top: 20px;
	display: block;
	width: 100%;
	height: 300px;
	padding: 0 10px;
}
